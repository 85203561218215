@mixin dropdownItem {
  align-items: center;

  min-height: 42px;
  cursor: pointer;
  padding: 8px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-lines: 2;
  line-height: 2;
}
