@use "src/styles/radar/mixins/textInput.mixin";

// default elements (V1)
input[type=number],
input[type=text],
input[type=tel]:not(.text-input),
input[type=email],
textarea {
  @include textInput.textInput;
}

// V2
.currency-input, .numeric-input, .text-input, .date-time {
  @include textInput.input-v2;

  &.ng-dirty.ng-invalid {
    border-color: var(--color-red);
  }

  &.ng-dirty.has-error {
    border-color: var(--color-red);
  }
}

.currency-input {
  padding-left: 42px;
}

.currency-input-container {
  display: flex;
  position: relative;

  &:before {
    content: '$';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-4);
    color: var(--color-black-50);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
