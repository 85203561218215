// colors
$v2-colors: (
  --color-white: #FFFFFF,
  --color-white-6: #ffffff0f,
  --color-white-50: #ffffff80,
  --color-black: #141514,
  --color-black-2: rgba(20, 21, 20, 0.02),
  --color-black-4: #1415140a,
  --color-black-8: #14151414,
  --color-black-30: #1415144D,
  --color-black-50: #14151480,
  --color-placeholder: #757575,
  --color-black-hover: #222322,
  --color-shadow: #13092f29,
  --color-purple: #6C40E3,
  --color-purple2: #F4F2FC,
  --color-purple-200: #E2D9F9,
  --color-purple-shadow: #D5C6FF,
  --color-purple-hover: #4C1FC7,
  --color-purple-secondary: #E9E1FF,
  --color-red: #E34040,
  --color-red-hover: #C71F1F,
  --color-bg: #F5F5F5,
  --color-green: #208220,
  --color-green-status: #20A720,
  --color-lightgreen: #20A720,
  --color-yellow: #FFF7E1,
  --color-yellow-2: #FB8C00,
  --color-lightblue: #E9F2FF,
  --color-pink: #e024ae,
  --color-pink-secondary: #FFE1F7,
  --color-blue: #2673e5,
  --color-blue-secondary: #E1EDFF,
  --color-aqua: #2697d9,
  --color-aqua-secondary: #E1F4FF,
  --color-lime: #78b718,
  --color-lime-secondary: #E4FFE4,
  --color-orange: #df851e,
  --color-orange-secondary: #FFF1E1,
  --color-grey: #808080,
  --color-grey-secondary: #ECECEC,
  --color-coral: #e34040,
  --color-coral-secondary: #FFE1E1,
  --color-yellow-tertiary: #FFF3E0,
  --color-lightgreen-hover: #1A861A,
  --color-lightgreen-active: #136413,
  --color-mystic-600: #00897b,
  --color-mystic-300: #99d0ca,
  --color-jazlyn-100: #FFF,
  --color-jazlyn-200: #fbfbfb,
  --color-jazlyn-300: #F6F6F6,
  --color-jazlyn-400: #F1F1F1,
  --color-jazlyn-500: #E2E0E3,
  --color-jazlyn-600: #C4C1C8,
  --color-jazlyn-700: #898390,
  --color-sun-crete-700: #C97000,
  --color-permanent-geranium-lake-700: #B72525,
  --color-blue-link: #2F7BEA,
);

:root {
  --alpha: 0.15;
  --gray-100: #f8f8f8;
  --gray-300: #B4C0CA;
  --gray-600: #9B9B9B;
  --gray-800: #172B4D;
  --txt-color: #363B45;
  --small-btn-bg: rgba(112, 112, 112, 0.05);
  --main-blue: #005CB4;
  --issuing-green: #91DF8F;
  --miles-purple: #C2B6F2;
  --contract-blue: #9AE9F3;
  --stroke: #EDEDED;
  --hover-blue: #006ACF;
  --placeholder: #89959F;
  --in-place-icon: #DADADA;
  --primary-btn-active: #00569F;
  --secondary-btn-active: #E3F3FE;
  --tooltip-bg: #FFFDF2;

  --nested-bg: rgba(1, 91, 169, 0.05);
  --nested-bg-hover: rgba(1, 91, 169, 0.09);


  --red: #D10000; //overnight
  --red6: #F5222D;
  --cancellation: #FF9688;

  --attention-bg: #FFF3B8;
  --deleted-bg: #FFCECE;

  // invoice status
  --linked-bg: #DBD2FF;
  --in-review-bg: #f3da67;
  --verified-bg: #CFF6FB;
  --paid-bg: #DEFADE;

  // order status colors
  --cherry-red: #A8071A;
  --cherry-red-bg: rgba(168, 7, 26, var(--alpha) );
  --cherry-red-toaster-bg: #A8071Aed;

  --geek-blue: #10239E;
  --geek-blue-bg: rgba(16, 35, 158, var(--alpha));

  --brick: #AD4E00;
  --brick-bg: rgba(173, 78, 0, var(--alpha));

  --dirty-yellow: #AD8B00;
  --dirty-yellow-bg: rgba(73, 139, 0, var(--alpha) );

  --confident-blue: #015BA9;
  --confident-blue-bg: rgba(1, 91, 169, var(--alpha) );

  --very-green: #237804;
  --very-green-bg: rgba(35, 120, 4, var(--alpha) );

  --purple: #391085;
  --purple-bg: rgba(57, 16, 133, var(--alpha) );

  --gray: #42526E;
  --gray-bg: #EBECF0;
  --light-gray-bg: #f6f7f8;

  --yellow: #F2C34A;

  --hover-bg: #F4F5F7;

  --loading-bar-background: var(--color-white);
  --loading-bar-body: var(--miles-purple);

  --datatable-pager-color: rgba(0, 0, 0, 0.54);
  --datatable-pager-background-hover: rgba(158, 158, 158, 0.2);
  --datatable-pager-active-background: rgba(158, 158, 158, 0.2);

  --status-orange: #F2994A;

  --currency-bg: #7070700d;

  // radar-table
  --cell-bg: #7070700d;
  --cell-currency-bg: #70707020;
  --checkbox: #8e8e8e;
  --search-bg: #FAFBFC;
  --search-border: #DFE1E5;

  // redesign
  @each $var, $value in $v2-colors {
    #{$var}: $value;
  }
}

@function hexToRGBString($hexColor) {
  @return "#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}";
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
