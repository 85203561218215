.custom-today {
  background-color: var(--stroke);
}

.rdr-today {
  color: var(--color-purple) !important;
}

.bs-datepicker {
  border-radius: 10px;
  box-shadow: 0px 8px 40px var(--color-shadow);

  * {
    color: var(--color-black);
  }

  .bs-datepicker-container {
    padding: 2px;

    .bs-datepicker-head {
      background-color: unset;
      padding: 0;
      height: 40px;

      bs-datepicker-navigation-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      button {
        border-radius: 8px;
        height: 40px;
      }

      .previous,
      .next {
        width: 40px;
        height: 40px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .bs-datepicker-body {
      padding: 0;
      border: none;

      th:first-child,
      .week {
        display: none;
      }
      
      td,
      td span,
      th {
        width: 40px;
        height: 40px;
      }

      .months,
      .years {
        td,
        td span,
        th {
          width: unset;
        }
      }

      th {
        color: var(--color-black);
      }

      td span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: background-color var(--global-transition);
      }

      td {
        .selected {
          background-color: var(--color-purple-secondary);
          color: var(--color-purple);
        }

        .select-start:not(.select-end) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .select-end:not(.select-start) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .is-highlighted {
          &.in-range {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &.select-start {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}
