$fieldWidth: 227px;
$labelWidth: 180px;

@mixin fieldView {
  padding-left: 8px;
  position: relative;
  padding-right: 8px;
  width: $fieldWidth;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--small-btn-bg);
  }
}

formly-field {
  display: flex;
  align-items: center;

  &:has(form-field-v2):not(:first-child) {
    margin-top: 16px;
  }
  &:has(checkbox-v2-wrapper):not(:first-child) {
    margin-top: 16px;
  }
  &:has(multi-wrapper-v2):not(:first-child) {
    margin-top: 16px;
  }
}

formly-form > formly-field > formly-group {
  width: 100%;
}

formly-form, radar-formly-form {
  .compact {
    editable {
      min-width: auto;
    }
    .formly-input {
      min-width: auto;
    }
    .view-mode {
      width: auto;
    }
    .label {
      width: auto;
      margin-right: 16px;
    }
  }
  .label {
    width: $labelWidth;
  }
  editable {
    width: $fieldWidth;
    div, span, input, dropdown, textarea {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    dropdown {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }
  }
  .formly-input {
    width: $fieldWidth;
  }

  .delete-icon,
  .copy-tcb,
  .redirect-link {
    visibility: hidden;
  }

  .hover {
    .delete-icon,
    .copy-tcb,
    .redirect-link {
      visibility: visible;
    }
  }
  .readonly-view {
    @include fieldView;
  }

  .view-mode {
    @include fieldView;

    border: 1px solid transparent;

    .pencil-icon {
      display: none;
    }

    &:hover {
      .pencil-icon {
        position: absolute;
        right: 0;
        display: flex;
      }
    }
  }

  .form-divider {
    display: block;
    height: 25px;
  }

  .input-error-border {
    border: 1px solid var(--red6);
  }

  .wide-field {
    editable,
    .view-mode,
    .formly-input,
    .readonly-view {
      width: 600px;
    }
  }
}

.col-3,
.col-4,
.col-6,
.col-8 {
  margin-top: 0 !important;
  padding-left: 8px;
  padding-right: 8px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child:not(:first-child) {
    padding-right: 0;
  }
}

formly-validation-message {
  display: block;
  color: var(--color-red);
}

form-field-v2 formly-validation-message,
repeat-v2 formly-validation-message {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  position: absolute;
  top: calc(100% + 2px);
  left: 12px;
}
