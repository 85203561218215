@use "bootstrap/scss/functions";

.text-truncate { @include text-truncate(); }

.text-capitalize { text-transform: capitalize; }

@each $class, $value in $v2-colors {
  .text-#{ str-replace($class, '--color-', '') } {
    color: $value;
  }
}

.text-align-center {
  text-align: center;
}
