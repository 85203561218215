.left dropdown, dropdown.left {
  .navbar-dropdown {
    left: 0;
  }
}

.top dropdown, dropdown.top {
  .navbar-dropdown {
    top: unset;
    bottom: 100%;
  }
}
