/* You can add global styles to this file, and also import other style files */
@import "styles/bootstrap";
@import "styles/radar";
@import "styles/third-party";

@font-face {
  font-family: 'Inter';
  src: url(assets/fonts/Inter-Regular.ttf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(assets/fonts/Inter-Medium.ttf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(assets/fonts/Inter-Bold.ttf) format("opentype");
  font-weight: 700;
}
