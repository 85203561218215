::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-black-30);
  border-radius: 10px;
  border: 3px solid #00000000;
  background-clip: padding-box;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
