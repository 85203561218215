.wide-modal {
  max-width: 940px;
}

.cross {
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-v2 {
  width: 320px;

  &.wide {
    width: 480px;
  }

  &.extra-wide {
    width: 640px;
    max-width: 640px;
  }

  &.wider {
    width: 1080px;
    max-width: 1080px;
  }

  .modal-content {
    padding: 28px 32px;
    border-radius: 20px;
    border: 0;
  }
}
