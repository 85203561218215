@mixin textInput {
  border-radius: 4px;
  height: 34px;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  box-shadow:none;
  border: 1px solid #B4C0CA;

  &:focus, &:active {
    border: 1px solid var(--main-blue);
  }
}

@mixin input-v2 {
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;

  padding: 7px 10px;
  background-color: var(--color-black-4);
  border: 1px solid transparent;
  border-radius: 6px;
  width: 100%;
  height: unset;
  line-height: 17px;
  transition: background-color var(--global-transition), border-color var(--global-transition);

  &.disabled, &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  &:hover:not(.disabled):not([disabled]) {
    background-color: var(--color-black-8);
  }

  &:focus {
    @include input-v2-focus;
  }
}

@mixin input-v2-focus {
  background-color: var(--color-black-8);
  border: 1px solid;
  border-color: var(--color-purple);
}

@mixin a-hover {
  &:hover {
    color: var(--color-purple);
  }
}

@mixin is-filter {
  padding: 8px 12px;
  height: 32px;
  overflow: hidden;
  background-color: transparent;
  border: 1px solid var(--color-black-8);
  border-radius: 8px;
  color: var(--color-black);

  &:hover {
    background-color: var(--color-black-2);
  }
}
