:root {
  --global-padding-left: 32px;
  --global-padding-right: 32px;
  --global-padding-top: 26px;
  --global-padding-bottom: 32px;
  --app-header-height: 60px;
  --global-transition: 200ms ease-out;
  --pipeline-card-border-radius: 12px;
  --layout-width: 1192px;
}

//redeclare bootstrap variables here
$blue: #005CB4;

$body-color: #363B45;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-box-shadow: unset;
$btn-focus-width: 0;
$hr-margin-y: 0;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;

$input-box-shadow: none;
$input-focus-width: 0;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$input-focus-border-color: var(--main-blue);

$font-family-base: 'Inter', 'Roboto', arial, sans-serif;

$label-margin-bottom: 0;
$link-hover-decoration: none;
$link-color: $blue;

$modal-header-border-width: 0;

$spacer: 32px;

$tooltip-bg: var(--txt-color);

