.ngx-datatable {
  datatable-header {
    height: unset !important;
  }

  datatable-row-wrapper:first-child datatable-body-row {
    margin-top: 0;
  }

  .datatable-header {
    .datatable-header-cell {
      &.currency {
        display: flex;
        justify-content: flex-end;
        padding-right: 8px;
      }
    }
  }

  .datatable-header-cell:first-of-type {
    padding-left: 8px;
  }

  .datatable-header-cell-label {
    font-size: 14px;
    color: var(--gray-600);
    cursor: pointer;
    margin-left: 8px;
  }

  &:not([readonly="true"]) {
    datatable-body-row:hover {
      background-color: var(--stroke);
      cursor: pointer;

      .remove {
        display: unset;
      }
    }
  }

  &.no-hover {
    datatable-body-row:hover {
      background-color: var(--small-btn-bg);
      cursor: default;
    }
  }

  .datatable-summary-row {
    datatable-body-row:hover {
      cursor: default;
      background: none;
    }
  }

  .remove {
    display: none;
  }

  .datatable-body-row {
    background-color: var(--small-btn-bg);
    font-size: 14px;
    height: 42px;
    align-items: center;
    margin-top: 8px;
    border-radius: 6px;

    .show-on-hover {
      visibility: hidden;
    }

    &:hover {
      .show-on-hover {
        visibility: visible;
      }
    }

    .datatable-row-center {
      align-items: center;

      .datatable-body-cell {
        line-height: normal;

        &.currency {
          display: flex;
          justify-content: flex-end;
          padding-right: 8px;
          font-weight: 600;
          background: var(--currency-bg);
          border-radius: 4px;

          div, typography {
            display: inline-block;
            text-align: right;
          }
        }

        display: flex;
        align-items: center;
        color: var(--txt-color);

        &:first-of-type {
          padding-left: 8px;
        }

        .datatable-body-cell-label {
          display: flex;
          width: inherit;

          & > * {
            margin-left: 8px;
          }

          & > span {
            width: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          & > .remove {
            margin-left: 0;
          }
        }
      }
    }
  }

  .empty-row {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray-300);
  }

  .datatable-summary-row {
    .datatable-body-row {
      background: none;
    }
  }

  .datatable-pager {
    margin: 0 10px;

    li {
      vertical-align: middle;

      &.pages {
        display: none !important;
      }

      &.active {
        display: unset !important;

        a {
          cursor: default !important;
        }
      }
    }

    li:nth-child(-n + 2),
    li:nth-last-child(-n + 2) {
      a {
        padding-top: 3px;
        opacity: 0.6;
      }

      &.disabled a {
        opacity: 0.2;
      }
    }

    a {
      height: 24px;
      min-width: 24px;
      line-height: 24px;
      padding: 0 6px;
      border-radius: 3px;
      margin: 6px 3px;
      text-align: center;
      color: var(--datatable-pager-color);
      text-decoration: none;
    }

    .datatable-icon-left::before {
      content: url('../../assets/svg/chevron-left.svg');
    }

    .datatable-icon-right::before {
      content: url('../../assets/svg/chevron-right.svg');
    }

    .datatable-icon-skip::before {
      content: url('../../assets/svg/chevrons-right.svg');
    }

    .datatable-icon-prev::before {
      content: url('../../assets/svg/chevrons-left.svg');
    }
  }

  .datatable-header-cell-template-wrap {
    .datatable-icon-up,
    .datatable-icon-down {
      transform: scale(0.5);
      opacity: 0.5;
    }

    .datatable-icon-up::before {
      content: url('../../assets/svg/chevron-top.svg');
    }

    .datatable-icon-down::before {
      content: url('../../assets/svg/chevron-bottom.svg');
    }
  }

  .unsort {
    * {
      cursor: default;
    }
  }
}
