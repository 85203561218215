@use "src/styles/radar/mixins/typeahead.mixin";

.dropdown-menu {
  min-width: 100%;
  max-width: 500px;
}

.dropdown-item {
  @include typeahead.dropdownItem;
}

.groupable {
  .dropdown-header {
    padding: 6px 12px;
    font-style: italic;
    color: var(--txt-color);
  }

  .dropdown-item {
    padding: 6px 24px;
  }
}

typeahead-v2 {
  .dropdown-menu {
    border: 0;
    border-radius: 8px;
    background: var(--color-white);
    box-shadow: 0 8px 20px var(--color-shadow);
    width: inherit;
    padding: 2px;
  }

  .dropdown-item {
    padding: 0;
    margin: 0;
    line-height: unset;
    min-height: auto;

    &.active {
      background-color: unset;
      color: unset;
    }
  }
}
