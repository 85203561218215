.rdr-toastr-container {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 9999;

  .ngx-toastr.rdr-toastr {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 19px 16px 20px 50px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;

    &.toast-error {
      background-color: var(--cherry-red-toaster-bg);
    }
    &.toast-info {
      color: var(--confident-blue);
      background-color: var(--placeholder);
      font-size: 14px;
    }
  }
}
