@use "src/styles/radar/mixins/state.mixin";

// atomic utilities
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.striped-row:hover {
  background-color: var(--gray-bg);
}

.overflow-visible {
  overflow: visible !important;
}

.float-right {
  float: right;
}

.hidden {
  visibility: hidden;
}

.nowrap {
  white-space: nowrap;
}

.hover-bg {
  &:hover {
    @include state.hovered-background;
  }
}

.hover-padding {
  padding: 4px 8px;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.text-uppercase {
  text-transform: uppercase;
}
.opacity-1 {
  opacity: 1 !important;
}
