@use "src/styles/radar/mixins/textInput.mixin";

body {
  font-size: 14px;
  font-family: $font-family-base;
  color: var(--color-black);
  line-height: unset;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: unset;

  @include textInput.a-hover;
}

// override Bootstrap _reboot rule
a:not([href]):not([class]) {
  &:hover {
    @include textInput.a-hover;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

hr {
  width: 100%;
  background-color: var(--color-jazlyn-300);
  border: 0;
  height: 1px;
  opacity: 1;
}

user-navbar,
button-icon-v2 .button,
button-v2 .button,
dropdown-item-v2,
button-user-picture-v2 .button,
a,
span {
  transition: color var(--global-transition), background-color var(--global-transition);
}
