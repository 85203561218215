.tooltip {
  .tooltip-arrow {
    display: none;
  }

  &.radar-tooltip {
    .tooltip-inner {
      padding: 0;
      margin: 0;
      color: var(--color-black);
      background-color: var(--color-white);
      box-shadow: 0px 8px 40px var(--color-shadow);
      border-radius: 10px;
      padding: 2px;
      white-space: nowrap;
      padding: 24px 20px;
      max-width: unset;
    }
  }

  &.show {
    opacity: 1;
  }
}

// known issue of ngx-bootstrap
// https://github.com/valor-software/ngx-bootstrap/issues/6492
// TODO: Remove when ngx-bootstrap v10.3.0 becomes available
.popover {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
}
  
.popover-arrow {
  position: absolute;
}
  
.tooltip {
  position: absolute;

  .tooltip-inner {
    padding: 4px 6px;
    border-radius: 4px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 12px;
    font-weight: 500;
  }
}
  
.tooltip-arrow {
  visibility: hidden;
}